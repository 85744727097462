import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../components/carousel'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Hat Channels & Smurf Conduit' },
    { img: data.i2, caption: 'Hat Channels under Epicore MSR' },
    { img: data.i3, caption: 'Suspended Drywall Ceiling' },
    { img: data.i4, caption: 'Exposed Epicore MSR as Loft Ceiling' },
    { img: data.i5, caption: 'Exposed Epicore MSR as Loft Ceiling' },
    { img: data.i6, caption: 'Epicore MSR 22 ga - Exposed Loft' },
    { img: data.i7, caption: 'Epicore MSR Painted' },
    { img: data.i8, caption: 'Epicore MSR 20 ga Painted' },
    { img: data.i9, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i10, caption: 'Painted 20 Gage Epicore MSR Deck' },
    { img: data.i11, caption: 'Painted 20 Gage Epicore MSR Deck' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: Ceiling Treatment" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>Ceiling Treatment</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p> There are many different ceiling treatment options available with the Epicore
          MSR Composite Floor System. If a finished drywall ceiling is desired, you can
          shoot hat channels directly to the underside of the deck and screw the drywall on
          the hat channels, or you can hang a metal grid for a suspended drywall ceiling.
          If an urban loft look is desired, you can leave the bottom of the Epicore MSR
          deck exposed galvanized. Another popular option is to simply paint the underside
          of the Epicore MSR deck for a linear plank or tongue & groove appearance.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/01-CEILI330_(34246).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/02-CEILI410_(40273).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/03-CEILI440_(40690).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/04-CEILI260_(34288).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/05-CEILI270_(34278).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/06-CEILI450_(40585).png" }) {
            ...GalleryCarouselImageFragment },
        i7: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/07-CEILI110_(40010).png" }) {
            ...GalleryCarouselImageFragment },
        i8: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/08-CEILI030_(41626).png" }) {
            ...GalleryCarouselImageFragment },
        i9: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/09-Mason110_(41957).png" }) {
            ...GalleryCarouselImageFragment },
        i10: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/10-CEILI570_(42004).png" }) {
            ...GalleryCarouselImageFragment },
        i11: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/gallery/11-CEILI580_(42005).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
